<template>
  <v-list dense nav>
    <template v-for="(item, index) in mainMenu">
      <v-menu v-if="item.addlinks" :key="index">
        <template v-slot:activator="{ on: menu }">
          <v-list-item
            style="font-size: 13px; font-weight: 500;"
            color="warning"
            v-on="{ ...menu }"
          >
            {{ item.title }}
          </v-list-item>
        </template>
        <v-list>
          <v-list-item
            color="warning"
            v-for="(subitem, index) in item.addlinks"
            :key="index"
            :to="subitem.to"
          >
            <v-list-item-title
              style="font-size: 13px !important; font-weight: 500;"
            >
              {{ subitem.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-list-item v-else :to="item.to" link :key="index" color="warning">
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      mainMenu: [
        {
          to: "/",
          title: "INICIO"
        },
        {
          to: "/Nosotros",
          title: "NOSOTROS",
          addlinks: [
            {
              to: "/Nosotros",
              title: "QUIENES SOMOS"
            },
            {
              to: "/Nosotros",
              title: "NUESTRA DIRECTORA"
            },
            {
              to: "/Nosotros",
              title: "NUESTRO EQUIPO"
            }
          ]
        },
        {
          to: "/Como-lo-hacemos",
          title: "¿CÓMO LO HACEMOS?"
        },
        {
          to: "/Admisiones",
          title: "ADMISIONES"
        },
        {
          to: "/Contacto",
          title: "CONTACTO"
        }
      ]
    };
  }
};
</script>
