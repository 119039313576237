<template>
  <v-container class="faq" fluid>
    <v-row class="AzulDark--text" justify="center">
      <v-col cols="12">
        <h2 class="text-center">Faq</h2>
      </v-col>
      <v-col cols="12" md="10" lg="8">
        <v-expansion-panels v-model="panel" multiple accordion hover>
          <v-expansion-panel v-for="(item, index) in faq" :key="index">
            <v-expansion-panel-header>
              <h5 class="AzulDark--text">{{ item.title }}</h5>
              <template v-slot:actions>
                <v-icon color="pm_blue">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="faq-content my-2">{{ item.description }}</p>
              <p
                class="faq-content mb-0"
                :v-if="item.content"
                v-for="(text, index) in item.content"
                :key="index"
              >
                {{ text }}
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Faq",
  props: {
    faq: Array
  },
  data() {
    return {
      panel: [0, 1]
    };
  }
};
</script>

<style>
.faq {
  background-image: url("../assets/backgrounds/bg_faq.jpg");
  background-size: cover;
  background-position: bottom center;
  position: relative;
  padding: 5rem !important;
  padding-bottom: 36rem !important;
}
.faq h2 {
  font-size: 36px;
  margin-bottom: 2rem;
}
.faq .v-expansion-panels {
  border-radius: 10px;
}
.faq .v-expansion-panels h5 {
  text-transform: initial;
  font-size: 17px;
  font-weight: 600;
}
.faq .v-expansion-panels .v-expansion-panel {
  background: #dedfdf !important;
}
.faq .v-expansion-panels .v-expansion-panel:not(:first-child):after {
  border-color: #5dbdb1 !important;
}
@media (max-width: 1024px) {
  .faq {
    padding: 2rem !important;
    padding-bottom: 36rem !important;
  }
}
@media (max-width: 1024px) {
  .faq {
    padding-bottom: 38rem !important;
  }
}
@media (max-width: 450px) {
  .faq {
    padding: 1rem !important;
    padding-bottom: 34rem !important;
  }
  .faq h2 {
    font-size: 36px;
    margin-bottom: 1rem;
  }
}
</style>
