<template>
  <v-container fluid class="full-footer py-0">
    <v-row class="footer-pos">
      <img class="jungle" src="@/assets/backgrounds/bg_footer.png" />
      <a
        v-for="(social, index) in socials"
        :key="index"
        :class="social.style"
        :href="social.link"
        target="_blank"
      >
        <img :src="social.img" />
      </a>
    </v-row>
    <v-row class="footer py-2" justify="center">
      <v-col class="responsive-social" cols="12">
        <a
          class="px-2"
          v-for="(link, index) in socials"
          :href="link.link"
          :key="index"
        >
          <img :src="link.imgRes" :alt="'pulgas' + link.style" />
        </a>
      </v-col>
      <v-col class="text-center" cols="12">
        <v-btn
          v-for="(link, index) in links"
          :key="index"
          color="white"
          text
          rounded
          class="my-sm-2"
          :to="link.link"
        >
          {{ link.name }}
        </v-btn>
      </v-col>
      <v-col class="text-center white--text font-weight-bold" cols="12">
        <p class="mb-1">Términos y Condiciones / Politicas de Privacidad</p>
        <p class="mb-1">Teléfono: (031) 643-2891 / Celular: 313 599 6700</p>
        <p class="mb-4">
          E-mail: laspulgasdelamona@gmail.com / pulgas.jardin@gmail.com
        </p>
        <p>
          <span class="body-2">
            Powered By </span><a class="ulr-EAL" href="https://enalgunlugarestudio.com" target="_blank">En Algún Lugar Estudio</a><span> © {{ date.getFullYear() }}/ All
            Rights Reserved
          </span>
        </p>
      </v-col>
      <img class="left-tree" src="@/assets/icons/left_tree.svg" />
      <img class="right-tree" src="@/assets/icons/right_tree.svg" />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      date: new Date(),
      links: [
        {
          name: "Inicio",
          link: "/"
        },
        {
          name: "¿Como lo hacemos?",
          link: "/Como-lo-hacemos"
        },
        {
          name: "Calendario",
          link: "/Calendario"
        },
        {
          name: "Admisiones",
          link: "/Admisiones"
        },
        {
          name: "Contacto",
          link: "/Contacto"
        }
      ],
      socials: [
        {
          style: "facebook-poster",
          link: "https://www.facebook.com/laspulgasdelamona",
          img: require("@/assets/icons/facebook_poster.svg"),
          imgRes: require("@/assets/icons/footer_facebook.png")
        },
        {
          style: "instagram-poster",
          link:
            "https://www.instagram.com/jardininfantillaspulgas/?utm_source=ig_embed",
          img: require("@/assets/icons/instagram_poster.svg"),
          imgRes: require("@/assets/icons/footer_instagram.png")
        },
        {
          style: "youtube-poster",
          link: "https://www.youtube.com/user/pulgasdelamona",
          imgRes: require("@/assets/icons/footer_youtube.png")
          // imgRes: require()
        }
      ]
    };
  }
};
</script>

<style>
.full-footer {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: -10%;
  left: 0;
}
.footer-pos {
  position: relative;
}
.jungle {
  width: 100%;
  height: auto;
  pointer-events: none;
}
.facebook-poster,
.instagram-poster,
.youtube-poster {
  position: absolute;
  z-index: 3;
}
.facebook-poster {
  bottom: 12%;
  left: 50%;
}
.instagram-poster {
  bottom: 5%;
  left: 56%;
}
.youtube-poster {
  bottom: 4%;
  left: 63%;
}
.facebook-poster img,
.instagram-poster img,
.youtube-poster img {
  height: 100px;
}
.footer {
  background-image: url("../assets/backgrounds/bg_footer2.png");
  background-size: cover;
  position: relative;
}
.footer .responsive-social {
  display: none;
}
.footer .responsive-social a img {
  width: 30px;
}
.footer .left-tree,
.footer .right-tree {
  width: 300px;
  height: auto;
  position: absolute;
  bottom: 84%;
  z-index: 1;
}
.footer .left-tree {
  left: 3%;
}
.footer .right-tree {
  right: 8%;
}
.footer .ulr-EAL { color: white; text-decoration: none; }
.footer .ulr-EAL:hover { color: #f16744 }

@media (max-width: 1024px) {
  .full-footer {
    bottom: -3%;
  }
  .facebook-poster {
    bottom: 12%;
    left: 50%;
  }
  .instagram-poster {
    bottom: 5%;
    left: 56%;
  }
  .youtube-poster {
    bottom: 4%;
    left: 63%;
  }
  .facebook-poster img,
  .instagram-poster img,
  .youtube-poster img {
    max-width: 55px;
    height: 90px;
  }
  .footer .left-tree,
  .footer .right-tree {
    width: 240px;
    position: absolute;
    bottom: 80%;
  }
  .footer .left-tree {
    left: 0%;
  }
  .footer .right-tree {
    right: 0%;
  }
}
@media (max-width: 768px) {
  .full-footer {
    bottom: 0%;
  }
  .facebook-poster img,
  .instagram-poster img,
  .youtube-poster img {
    max-width: 36px;
    height: 60px;
  }
  .footer .left-tree,
  .footer .right-tree {
    width: 140px;
    position: absolute;
    bottom: 90%;
  }
}
@media (max-width: 450px) {
  .full-footer {
    bottom: 0%;
  }
  .footer-pos {
    display: none;
  }
  .facebook-poster img,
  .instagram-poster img,
  .youtube-poster img {
    max-width: 23px;
    height: 40px;
  }
  .footer .left-tree,
  .footer .right-tree {
    width: 80px;
    bottom: 95%;
    display: none;
  }
  .footer .responsive-social {
    display: flex;
    justify-content: center;
  }
  .footer .left-tree {
    left: 0%;
  }
  .footer .right-tree {
    right: 0%;
  }
}
</style>
