<template>
  <div>
    <Carousel class="mb-6" />
    <IconAndTitle :icon="icon" :title="title" />
    <v-container class="info-home">
      <v-row justify="center">
        <v-col class="text-center" cols="12" md="6">
          <h6 class="pm_orange--text light">
            Nos caracterizamos por ser un jardín personalizado y esto nos ha
            convertido en una
          </h6>
          <h5 class="pm_orange--text mb-6">GRAN FAMILIA</h5>
          <p class="mb-0">
            Nuestra metodología de educación está basada en el método
          </p>
          <b>REGGIO EMILIA, MONTESSORI y TRADICIONAL.</b>
          <p class="mt-4">
            Formando niños y niñas auténticos respetando su autonomía y
            personalidad.
          </p>
        </v-col>
        <v-col class="text-center" cols="12">
          <h4 class="pm_purple--text mb-3">jornadas</h4>
          <h5 class="pm_orange--text mb-1">nuestra jornada</h5>
          <h6 class="pm_blue--text mb-1">lunes a viernes</h6>
          <p class="mb-6">8:30 am - 12:30 pm</p>
          <h5 class="pm_orange--text mb-1">Jornada continua</h5>
          <h6 class="pm_blue--text mb-1">lunes a viernes</h6>
          <p>8:30 am - 3:00 pm</p>
        </v-col>
      </v-row>
    </v-container>
    <Caption />
    <div class="correct-content">
      <MapFooter />
      <Footer />
    </div>
  </div>
</template>

<script>
import Carousel from "../components/Carousel";
import Caption from "../components/Caption";
import MapFooter from "../components/MapFooter";

export default {
  name: "home",
  components: {
    Carousel,
    Caption,
    MapFooter
  },
  data() {
    return {
      icon: require("@/assets/icons/icon_home.svg"),
      title: "Porqué somos diferentes"
    };
  }
};
</script>

<style>
.info-home {
  padding: 0rem 12px !important;
  padding-bottom: 50px !important;
}
.info-home h6.light {
  font-weight: 400;
  font-size: 20px;
}
.info-home h6 {
  font-size: 18px;
}
.info-home h5 {
  font-weight: 800;
  font-size: 22px;
}
.info-home h4 {
  font-size: 26px;
}
.info-home p {
  font-weight: 300;
}
.correct-content .map-footer {
  margin-bottom: 10rem;
}

@media (max-width: 1900px) {
  .correct-content .map-footer {
    margin-bottom: 6rem;
  }
}
@media (max-width: 768px) {
  .correct-content .map-footer {
    margin-bottom: 4rem;
  }
}
@media (max-width: 450px) {
  .info-home {
    padding: 12px;
  }
}
</style>
