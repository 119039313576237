<template>
<!-- cycle -->
  <v-carousel
    
    height="850"
    hide-delimiter-background
    next-icon="fa-long-arrow-alt-right"
    prev-icon="fa-long-arrow-alt-left"
  >
    <v-carousel-item
      v-for="(item, index) in items"
      :key="index"
      :src="item.src"
      :class="item.class"
    >
      <a
        v-if="item.url"
        :href="item.url"
        :target="item.target ? item.target : '_self'"
        class="text-decoration-none"
      >
        <v-row class="carousel-content">
          <v-col
            cols="12"
            md="9"
            lg="5"
            xl="6"
            v-bind:class="item.class ? item.class : ''"
          >
            <h4>{{ item.textOne }}</h4>
            <h3>{{ item.textTwo }}</h3>
            <div class="mt-8 btn_banner">
              <a
                :href="item.url"
                class="btn-banner text-decoration-none white--text white--text font-weight-bold"
              >
                {{ item.btn }}
              </a>
            </div>
          </v-col>
        </v-row>
      </a>
      <v-row v-else class="carousel-content">
        <v-col cols="12" md="9" lg="5" xl="5">
          <h4>{{ item.textOne }}</h4>
          <h3>{{ item.textTwo }}</h3>
        </v-col>
      </v-row>
    </v-carousel-item>
    <img class="cloud" src="@/assets/backgrounds/cloud.svg" />
  </v-carousel>
</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      items: [
        {
          src: require("@/assets/backgrounds/bg_home10.webp"),
          url: "/Admisiones",
          btn: "Matriculas abiertas",
          class: "banner-10"
        },
        {
          src: require("@/assets/backgrounds/bg_home9.webp"),
          textOne: "Somos pedagogas,",
          textTwo:
            "terapeutas de lenguaje, ocupacionales, psicólogas y fisioterapeutas.",
          url: "/Admisiones",
          btn: "Matrículas abiertas",
          class: "mt-10"
        },
        /* {
          src: require("@/assets/backgrounds/bg_home8.webp"),
          url: '/Admisiones',
          btn: 'Inscripciones',
        }, */
        {
          src: require("@/assets/backgrounds/bg_home6.jpg"),
          textOne: "Somos un taller de",
          textTwo: "Estimulación adecuada pre-jardín y jardín",
        },
        {
          src: require("@/assets/backgrounds/bg_home4.jpg"),
          textOne: "Somos un taller de",
          textTwo: "Estimulación adecuada pre-jardín y jardín",
        }
      ]
    };
  }
};
</script>

<style>
.cloud {
  width: 100%;
  position: absolute;
  bottom: -5%;
  left: 0;
  right: 0;
  z-index: 1;
}
.v-window__next,
.v-window__prev {
  background: rgba(0, 0, 0, 0) !important;
}
.v-window__next i,
.v-window__prev i {
  font-size: 50px !important;
}
.v-application--is-ltr .v-window__next {
  right: 5% !important;
}
.v-application--is-ltr .v-window__prev {
  left: 5% !important;
}
.carousel-content {
  padding: 6rem 11rem;
  text-shadow: 1px 1px 4px #00000088;
  height: 100%;
}
.carousel-content h3,
.carousel-content h4 {
  margin-bottom: 0 !important;
  color: #fff;
}
.carousel-content h3 {
  font-size: 2.8rem;
  line-height: 3.4rem;
}
.carousel-content h4 {
  font-size: 2rem;
  line-height: 2.6rem;
}
.carousel-a {
  height: 100%;
}
.block__btn__banner {
  /* margin-top: 5vw; */
}
.btn-banner {
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  background-color: #ffb50b;
  padding: 0.1vw 3vw;
  border-radius: 15px;
}
.btn-banner:hover { opacity: 0.8; }
@media (max-width: 1024px) {
  .carousel-content {
    padding: 6rem;
  }
}
@media (max-width: 768px) {
  .v-carousel,
  .v-carousel__item {
    height: 600px !important;
  }
}
@media (max-width: 450px) {
  .carousel-content {
    padding: 1rem;
  }
  .carousel-content h3 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .carousel-content h4 {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .v-carousel,
  .v-carousel__item {
    height: 500px !important;
  }
}
div.banner-10 .btn_banner {
  margin-top: 40vh !important;
  margin-left: 6vw;
}
div.banner-10 .btn_banner a {
  background-color: #4b00af;
  font-size: 1.5rem;
}
@media (min-width: 1600px) {
  div.banner-10 .btn_banner a {
    font-size: 2rem;
  }
}
@media (max-width: 1600px) {
  div.banner-10 {
    margin-top: 26vh;
    margin-left: 0;
    padding-left: 0;
  }
}
</style>
