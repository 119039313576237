import Vue from "vue";
import Vuex from "vuex";
import path from "../utils/Params";

const Api = path["path"];

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menu: [
      { title: "Inicio", to: "/", divider: true, inset: true },
      {
        title: "Nosotros",
        to: "/Nosotros",
        divider: true,
        inset: true
      },
      {
        title: "¿Cómo lo hacemos?",
        to: "/Como-lo-hacemos",
        divider: true,
        inset: true
      },
      {
        title: "NotiPulgas",
        to: "/NotiPulgas",
        divider: true,
        inset: true
      },
      {
        title: "Admisiones",
        to: "/Admisiones",
        divider: true,
        inset: true
      },
      {
        title: "Contacto",
        to: "/Contacto",
        divider: true,
        inset: true
      }
    ],
    alert: false,
    dataAlert: {
      type: "success",
      text: "Mensaje Alert "
    },
    typeForm: "",
    Eventos: [],
    dataEvento: [],
    ShowEvento: false
  },
  mutations: {
    setAlert(state, Response) {
      state.alert = true;
      state.dataAlert = {
        type: Response.type,
        text: Response.text
      };
    },
    setAlertClose(state) {
      state.alert = false;
    },
    setTypeForm(state, type) {
      state.typeForm = type;
    },
    setEvento(state, Response) {
      state.Eventos = Response;
    },
    setDataEvento(state, data) {
      state.dataEvento = data;
      state.ShowEvento = true;
    },
    setEventoClose(state) {
      state.ShowEvento = false;
    }
  },
  actions: {
    GetEventos: async function({ commit }) {
      const Response = await fetch(Api + "Eventos.json");
      const dataResponse = await Response.json();
      commit("setEvento", dataResponse);
    },
    PostData: async function({ commit }, data) {
      const response = await fetch(Api + "Web/" + data["url"], {
        method: "POST",
        body: JSON.stringify(data["form"]),
        headers: {
          "Content-Type": "application/json"
        }
      });

      const dataResponse = await response.json();
      if (dataResponse.statusCode == 201) {
        let alert = {
          type: "success",
          text: "Correo Enviado Correctamente"
        };
        commit("setAlert", alert);
      } else {
        let alert = {
          type: "error",
          text: "Correo No Enviado. Intentar mas tarde"
        };
        commit("setTypeForm", data["url"]);
        commit("setAlert", alert);
      }
    }
  },
  modules: {}
});
