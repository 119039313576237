<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h2 class="text-center pm_purple--text">CONTACTO</h2>
      </v-col>
      <v-col cols="12">
        <p>
          Si tienes alguna duda o quieres conocernos, envianos tus comentarios
        </p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-form class="form-contact" @submit.prevent="submit()">
          <v-text-field
            label="Nombre"
            name="Nombre"
            v-model="form.nombre"
            color="pm_purple"
            type="text"
          />
          <v-text-field
            label="E-mail"
            name="Email"
            v-model="form.correo"
            :rules="[rules.required, rules.email]"
            color="pm_purple"
            type="email"
          />
          <v-text-field
            label="Teléfono"
            name="telefono"
            v-model="form.telefono"
            color="pm_purple"
            type="text"
          />
          <v-textarea
            v-model="form.mensaje"
            label="Mensaje"
            color="pm_purple"
            outlined
          ></v-textarea>
          <v-alert
            v-model="alert.state"
            class="text-center mt-6"
            :color="alert.color"
            dark
            dismissible
          >
            {{ alert.message }}
          </v-alert>
          <v-btn class="ma-2" tile outlined color="pm_purple" type="submit">
            Enviar
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row class="ma-2" v-for="item in items" :key="item.icon">
          <v-col class="pa-0" cols="3" sm="1" md="2" lg="1">
            <v-avatar tile color="pm_purple" size="30" class="ma-3 facebook">
              <v-icon size="medium" class="iconFacebook" dark>
                {{ item.icon }}
              </v-icon>
            </v-avatar>
          </v-col>
          <v-col
            cols="9"
            sm="11"
            md="10"
            lg="11"
            class="align-self-center py-1"
          >
            <span v-for="textItem in item.text" :key="textItem.text">
              {{ textItem.text }}<br />
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: "FormContact",

  data() {
    return {
      form: {
        nombre: "",
        correo: "",
        telefono: "",
        mensaje: ""
      },
      rules: {
        required: value => !!value || "Required.",
        counter: value => value.length <= 20 || "Máximo 20 caracteres",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "El email es invalido";
        }
      },
      alert: {
        state: false,
        color: "",
        message: ""
      },
      message: {
        sendSuccess: "* El mensaje ha sido enviado exitosamente",
        sendError: "No se pudo enviar el mensaje. Vuelve a intentarlo más tarde"
      },
      items: [
        {
          icon: "fas fa-envelope",
          text: [
            { text: "laspulgasdelamona@gmail.com" },
            { text: "pulgas.jardin@gmail.com" }
          ]
        },
        {
          icon: "mdi-phone",
          text: [
            { text: "Teléfono: (031) 643-2891" },
            { text: "Celular: 313 599 6700" }
          ]
        },
        {
          icon: "fas fa-map-marker-alt",
          text: [{ text: "Cra 19 # 85-23, Bogotá" }]
        }
      ]
    };
  },
  methods: {
    submit() {
      /* eslint-disable no-console */
      const templateParams = {
        name: this.form.nombre,
        email: this.form.correo,
        phone: this.form.telefono,
        message: this.form.mensaje
      };
      console.log(templateParams)
      emailjs.send('service_flx3hm8','template_formContact', templateParams, 'user_DeLKhBJunAnOaTBJCNcou')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.templateParams);
        this.alert = {
          state: true,
          color: 'success',
          message: `${this.message.sendSuccess}`
        }
      }, (err) => {
        console.log('FAILED...', err);
        this.alert = {
          state: true,
          color: 'error',
          message: `${this.message.sendError} + codigo + ${err}`
        }
      });
      /* eslint-enable no-console */
    }
  },
}
</script>
