<template>
  <v-container fluid class="navigation">
    <v-navigation-drawer
      class="d-xs-flex d-lg-none"
      v-model="drawer"
      color="navColor"
      temporary
      app
    >
      <Navigation />
    </v-navigation-drawer>
    <v-app-bar app height="90px" color="navColor">
      <v-app-bar-nav-icon
        class="d-xs-flex d-lg-none"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer class="d-none d-lg-flex"></v-spacer>
      <v-toolbar-title class="pr-5">
        <v-img :src="img" width="140" />
      </v-toolbar-title>
      <v-spacer class="d-none d-lg-flex"></v-spacer>
      <v-toolbar-items
        class="d-none d-lg-flex"
        v-for="item in mainMenu"
        :key="item.title"
      >
        <v-menu v-if="item.addlinks" open-on-hover offset-y>
          <template v-slot:activator="{ on: menu }">
            <v-btn text color="Naranja" v-on="{ ...menu }">
              {{ item.title }}
            </v-btn>
          </template>
          <v-list color="navColor">
            <v-list-item
              v-for="(subitem, index) in item.addlinks"
              :key="index"
              :to="subitem.to"
            >
              <v-list-item-title class="pm_blue--text">
                {{ subitem.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn v-else text :to="item.to" color="Naranja">
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
      <v-spacer class="d-xs-flex d-lg-none"></v-spacer>
      <v-avatar
        tile
        v-for="(icon, index) in icons"
        :key="index"
        size="45"
        @click="redir(icon.href)"
      >
        <img class="px-1" :src="icon.icon" alt="John" />
      </v-avatar>
    </v-app-bar>
  </v-container>
</template>

<script>
import Navigation from "./Navigation.vue";
// import { mapState } from "vuex";
export default {
  name: "Header",
  components: {
    Navigation
  },
  data() {
    return {
      img: require("@/assets/icons/full-logo2.svg"),
      drawer: false,
      icons: [
        {
          icon: require("@/assets/icons/icon-facebook.svg"),
          href: "https://www.facebook.com/laspulgasdelamona"
        },
        {
          icon: require("@/assets/icons/icon-instagram.svg"),
          href:
            "https://www.instagram.com/jardininfantillaspulgas/?utm_source=ig_embed"
        },
        {
          icon: require("@/assets/icons/icon-youtube.svg"),
          href: "https://www.youtube.com/user/pulgasdelamona"
        }
      ],
      mainMenu: [
        {
          to: "/",
          title: "INICIO"
        },
        {
          to: "/Nosotros",
          title: "NOSOTROS",
          addlinks: [
            {
              to: "/Nosotros#padres",
              title: "QUIENES SOMOS"
            },
            {
              to: "/Nosotros#directora",
              title: "NUESTRA DIRECTORA"
            },
            {
              to: "/Nosotros#equipo",
              title: "NUESTRO EQUIPO"
            }
          ]
        },
        {
          to: "/Como-lo-hacemos",
          title: "¿CÓMO LO HACEMOS?"
        },
        {
          to: "/Admisiones",
          title: "ADMISIONES"
        },
        {
          to: "/Contacto",
          title: "CONTACTO"
        }
      ]
    };
  },
  methods: {
    redir(item) {
      window.open(item);
    }
  }
  // computed: {
  //   ...mapState(["menu"])
  // }
};
</script>

<style>
.navigation {
  padding: 0px !important;
}
.icon {
  cursor: pointer;
}
</style>
