<template>
  <v-container class="items-how mb-12">
    <v-row class="text-center" justify="center">
      <v-col cols="12" sm="10" md="9">
        <p>
          Hemos creado un espacio adecuado en el tenemos en cuenta la etapa de
          desarrollo de tu hijo (a), un lugar cómodo donde podran desarrollar
          sus capacidades congnitivas sintiendose grandes, únicos y seguros.
          medio de diferentes actividades de adaptación facilitamos la manera en
          que conocen el mundo, socializan y fortalecen su autoestima.
        </p>
      </v-col>
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="12" sm="9" md="6" xl="5">
            <img src="../assets/icons/title_pulga.svg" alt="" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="8" md="4" class="text-center" justify="center">
        <v-row
          class="text-center mb-4"
          justify="center"
          v-for="(text, index) in texts"
          :key="index"
        >
          <h3 class="mb-4 pm_purple--text">
            {{ text.title }}
          </h3>
          <p>
            {{ text.data }}
          </p>
        </v-row>
      </v-col>
      <v-col class="text-center carousel-text" cols="12">
        <i class="fas fa-heart"></i>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-btn
        v-for="(btn, index) in scrollBtn"
        min-width="150"
        class="mx-4 mb-4"
        color="pm_purple"
        dark
        rounded
        :key="index"
        @click="
          $vuetify.goTo(`#${btn.to}`, {
            duration: 400,
            offset: 0,
            easing: 'easeInOutCubic'
          })
        "
      >
        {{ btn.name }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ComoComponent",
  props: {
    texts: Array,
    scrollBtn: Array
  }
};
</script>

<style>
.items-how h3 {
  font-size: 26px;
}
.items-how .carousel-text i {
  font-size: 34px;
  color: #cddc39;
  margin-bottom: 1.5rem;
}
</style>
