<template>
  <div class="map-footer">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d994.1410933486084!2d-74.05799107084442!3d4.6714744997879265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9af3858744bf%3A0x38e9f3e3d0b7c2b7!2sAk.%2019%20%2385-23%2C%20Bogot%C3%A1!5e0!3m2!1ses!2sco!4v1604674000990!5m2!1ses!2sco"
      width="100%"
      frameborder="0"
      style="border:0;"
      allowfullscreen=""
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "MapFooter"
};
</script>

<style>
.map-footer {
  margin-bottom: 20rem;
}
.map-footer iframe {
  height: 700px;
}
@media (max-width: 1900px) {
  .map-footer {
    margin-bottom: 16rem;
  }
}
@media (max-width: 768px) {
  .map-footer {
    margin-bottom: 3rem;
  }
  .map-footer {
    height: 730px;
  }
  .map-footer iframe {
    height: 400px;
  }
}
@media (max-width: 450px) {
  .map-footer {
    height: 780px;
    margin-bottom: 0;
  }
  .map-footer iframe {
    height: 400px;
  }
}
</style>
