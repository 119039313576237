<template>
  <div class="under pa-12">
    <v-row align="center" justify="center">
      <v-col cols="12" class="text-center pa-12">
        <v-icon dark size="64">fa-cogs</v-icon>
      </v-col>
      <v-col cols="12" class="text-center pa-12">
        <h1>Under contruction</h1>
      </v-col>
      <v-col cols="12" class="text-center pa-12">
        <v-btn to="/" tile x-large color="Morado" dark>Volver al Inicio</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "Under"
};
</script>
<style>
.under {
  background-image: url("../assets/backgrounds/bg_caption.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 91vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: beige;
}
</style>
