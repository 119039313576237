<template>
  <v-container>
    <v-row justify="center">
      <v-col class="text-center" cols="10">
        <h3 class="mb-8 pm_blue--text">¡Cada pulga es única!</h3>
        <p>
          Motivamos y fortalecemos constantemente su autonomía a través de
          valores y conociendolos sin compararlos con los demás. Aprendemos a
          traves de el ensayo y error; buscamos desarrollar sus habilidades
          intelectuales, afectivas, sociales y de lenguaje brindándoles un
          ambiente de seguridad con el fin de generar en la pulga confianza en
          sí misma.
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        v-for="(classe, index) in ludicas"
        class="d-flex justify-center mt-12"
        cols="12"
        md="6"
        lg="4"
        :key="index"
      >
        <div class="activiti-card" :style="{ background: classe.bg }">
          <div class="card-icon">
            <img :src="classe.icon" alt="" />
          </div>
          <div class="card-content">
            <h3>{{ classe.title }}</h3>
            <div class="description">
              <small>Profesor</small>
              <h5 class="mb-4">{{ classe.teacherName }}</h5>
              <p v-if="classe.description">{{ classe.description }}</p>
              <b>Metodología de trabajo:</b>
              <p v-for="(item, index) in classe.metodology" :key="index">
                {{ item }}
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Actividades",
  data() {
    return {
      ludicas: [
        {
          title: "Música",
          bg: "#03b9db",
          icon: require("@/assets/icons/icon-musica.svg"),
          teacherName: "Elizabeth Restrepo",
          description:
            "Gracias a mi experiencia de prácticas docentes en Brasil y Argentina, ha tomado de varios maestros y metodologías, algunos recursos y elementos didácticos aplicables a la iniciación de los más pequeños. Amo profundamente lo que hago, puedo decir con total certeza que mi niña interior nunca se hizo adulto... sigue ahí de ojos abiertos...siempre está buscando más niños y niños y niñas para encontrarse y jugar con ellos por medio de la música.",
          metodology: [
            "El cuento cantado, la ronda, la historia que hila lo mágico y lo real, la canción infantil y el mundo sonoro de los instrumentos, son parte de este hermoso comienzo que toca la musicalidad en el corazón de cada niño."
          ],
          img: require("@/assets/team/alizabeth_restrepo1.jpg")
        },
        {
          title: "Yoga Kids",
          bg: "#8443DC",
          icon: require("@/assets/icons/icon-yoga.svg"),
          teacherName: "Lisa Olea",
          description:
            "El yoga en los niños es una herramienta que trae múltiples beneficios para toda la vida!Una clase de yoga constante les ayuda a los niños a ser más fuertes y flexibles, también les ayuda a manejar mejor sus emociones y sobretodo les ayuda a ser seres humanos más conscientes, compasivos y felices.",
          metodology: [
            "Las clases son una combinación de mindfulness, meditación y movimiento con la ayuda de la música, el juego y el arte."
          ],
          img: require("@/assets/team/alizabeth_restrepo1.jpg")
        },
        {
          title: "Baile",
          bg: "#8443DC",
          icon: require("@/assets/icons/icon-dance.svg"),
          teacherName: "Daniel Otero",
          metodology: [
            "- Por medio del movimiento aprenden a expresar su seguridad corporal, de esta manera involucramos la creatividad artística.",
            "-	Enseñar y desarrollar capacidades corporales, a través de la Danza y el Baile, como una forma de desenvolver el desarrollo psicomotor, intelectual y afectivo de los niños. ",
            "- Se imparten clases de baile buscando el sentido del ritmo por medio de la música ",
            "-	Coordinación Corporal ",
            "-	Cualidades artísticas y al mismo tiempo se estimula el compañerismo, disciplina  y trabajo en grupo.",
            "-	Adicionalmente se realizan montajes coreográficos finales por cada curso, donde lo niños se les enseña una serie de pasos formando así, una rutina de baile y ellos atrás ves de la coreografía, demuestran todo lo aprendido durante el semestre."
          ],
          img: require("@/assets/team/alizabeth_restrepo1.jpg")
        }
      ]
    };
  }
};
</script>

<style>
.activiti-card {
  border-radius: 20px;
  text-align: center;
  padding: 2rem;
  max-width: 450px;
  position: relative;
}
.activiti-card:hover .card-icon {
  top: 0;
}
.activiti-card:hover .card-content {
  top: 0;
  height: 100%;
}
.activiti-card:hover .card-content .description {
  opacity: 1;
}
.activiti-card .card-icon {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  transition: 0.5s all;
}
.activiti-card .card-icon img {
  position: relative;
  padding: 0.3rem;
  background: #ff5c8f;
  border-radius: 100px;
  width: 120px;
}
.activiti-card .card-content {
  padding-top: 2rem;
  position: relative;
  color: #ffffff;
  top: 56%;
  height: 20%;
  overflow: hidden;
  transition: 0.5s all;
  transition-delay: 0.05s;
}
.activiti-card .card-content h3 {
  font-size: 26px;
}
.activiti-card .card-content .description {
  opacity: 0;
  transition: 0.3s;
  transition-delay: 0.2s;
}
.activiti-card .card-content img {
  width: 160px;
  border-radius: 100%;
  padding: 0.4rem;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='white' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e");
}
@media (max-width: 450px) {
  .activiti-card .card-content .description p {
    font-size: 14px;
  }
}
</style>
