<template>
  <div class="pt-12">
    <IconAndTitle :icon="icon" :title="title" />
    <v-container fluid>
      <v-row class="px-md-6" justify="center">
        <v-col class="pa-md-6" cols="12" xl="6" md="6">
          <FormContact />
        </v-col>
        <!-- <v-col class="pa-md-12" cols="12" xl="6" md="6">
          <FormAgenda />
        </v-col> -->
      </v-row>
    </v-container>
    <MapFooter />
    <Footer />
  </div>
</template>

<script>
// import FormAgenda from "../components/form/FormAgenda";
import FormContact from "../components/form/FormContact";
import MapFooter from "../components/MapFooter";
export default {
  name: "Contact",
  components: {
    // FormAgenda,
    FormContact,
    MapFooter
  },
  data() {
    return {
      icon: require("@/assets/icons/icon_contact.svg"),
      title: "CONTÁCTANOS"
    };
  }
};
</script>
