/* eslint-disable no-undef */
<template>
  <v-app>
    <Header />
    <FixedButtom
      v-for="(infobtn, index) in fixedbtn"
      :infobtn="infobtn"
      :key="index"
    />
    <v-content>
      <vue-page-transition name="fade-in-down">
        <router-view />
      </vue-page-transition>
    </v-content>
    <!-- <WhatsappButton /> -->
  </v-app>
</template>

<script>
import Header from "./components/Header/Header.vue";
// import WhatsappButton from "./components/WhatsappButton.vue";
export default {
  name: "App",
  components: {
    Header
  },
  metaInfo: {
    title: "Las Pulgas de la Mona | Jardín Infantil",
    titleTemplate: "%s | Las Pulgas de la Mona"
  },
  data() {
    return {
      bg_image: require("@/assets/under_construction.png"),
      fixedbtn: [
        {
          icon: require("@/assets/icons/agendar.svg"),
          text: "Agenda tu cita",
          textBold: "aqui",
          top: "topBtnOne",
          link: "/Contacto",
          blank: false
        },
        {
          icon: require("@/assets/icons/hablemos.svg"),
          text: "Hablanos",
          top: "topBtnTwo",
          link: "https://api.whatsapp.com/send?phone=573135996700&text=Hola,%20d%C3%A9janos%20tu%20mensaje",
          blank: true
        }
      ]
    };
  }
};
</script>
<style>
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.6s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(800px);
  opacity: 0;
}
.under-construction .v-responsive__content,
.v-image__image--cover {
  height: 100% !important;
  width: 100% !important;
}
h1,
h2,
h3,
h4 {
  font-family: "Fredoka One", cursive;
  text-transform: uppercase;
}
h5,
h6 {
  font-family: "Open Sans", sans-serif !important;
  text-transform: uppercase;
}
p,
span {
  font-family: "Open Sans", sans-serif !important;
}
#app {
  background-image: url("./assets/bg_main.jpg");
  background-position: center;
  background-size: cover;
}
.v-text-field .v-input__control,
.v-text-field .v-input__slot,
.v-text-field fieldset {
  border-color: inherit;
  border-radius: 0 !important;
}
</style>
