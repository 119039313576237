<template>
  <v-container class="banner text-center" fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h2>QUIENES SOMOS</h2>
      </v-col>
    </v-row>
    <img class="cloud" src="@/assets/backgrounds/cloud.svg" />
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.banner {
  position: relative;
  height: 500px;
  background-image: url("../assets/backgrounds/bg_nosotros.jpg");
  background-position: center;
  background-size: cover;
}
.banner h2 {
  color: #ffffff;
  text-shadow: 0px 0px 4px #000000aa;
  font-size: 2.5rem;
  letter-spacing: 0.3rem;
}
.banner .cloud {
  position: absolute;
}
</style>
