import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VuePageTransition from "vue-page-transition";
import VueCountdown from "@chenfengyuan/vue-countdown";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import VueCountdownTimer from "vuejs-countdown-timer";
import Under from "./components/Under.vue";
import Alert from "./components/Alert.vue";
import Carousel from "./components/Carousel.vue";
import IconAndTitle from "./components/IconAndTitle.vue";
import Footer from "./components/Footer.vue";
import FixedButtom from "./components/FixedButtom.vue";
import UnderConstruction from "./components/UnderConstruction.vue";
import VueMeta from "vue-meta";

Vue.use(VueMeta);
Vue.config.productionTip = false;

Vue.use(VuePageTransition);
Vue.use(VueCountdownTimer);

Vue.component(VueCountdown.name, VueCountdown);
Vue.component("Under", Under);
Vue.component("Alert", Alert);
Vue.component("Carousel", Carousel);
Vue.component("IconAndTitle", IconAndTitle);
Vue.component("Footer", Footer);
Vue.component("FixedButtom", FixedButtom);
Vue.component("UnderConstruction", UnderConstruction);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
