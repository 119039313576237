<template>
  <!-- <div class="fixed-btn" :style="{ top: infobtn.top + '%' }"> -->
  <a
    :href="infobtn.link"
    class="fixed-btn"
    :class="infobtn.top"
    :target="infobtn.blank ? '_blank' : null"
  >
    <img :src="infobtn.icon" alt="pulgas agendar" />
    <span>
      {{ infobtn.text }} <b v-if="infobtn.textBold">{{ infobtn.textBold }}</b>
    </span>
  </a>
</template>

<script>
export default {
  name: "FixedButtom",
  props: {
    infobtn: {
      type: Object
    }
  }
};
</script>
<style>
.fixed-btn {
  background: #8b67da;
  text-decoration: none;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  padding: 1rem;
  width: 160px;
  height: 70px;
  border-radius: 50px 0px 0px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: -88px;
  z-index: 200;
  box-sizing: border-box;
  transition: all 0.5s;
  cursor: pointer;
}
.topBtnOne {
  top: 200px;
}
.topBtnTwo {
  top: 290px;
}
.fixed-btn:hover {
  right: 0;
}
.fixed-btn img {
  max-width: 50px;
  max-height: 50px;
  margin-right: 0.5rem;
}
.fixed-btn span {
  font-size: 14px;
  color: #fff;
}
@media (max-width: 768px) {
  .topBtnOne {
    top: 160px;
  }
  .topBtnTwo {
    top: 240px;
  }
}
@media (max-width: 450px) {
  .topBtnOne {
    top: 120px;
  }
  .topBtnTwo {
    top: 190px;
  }
  .fixed-btn {
    padding: 0.8rem;
    width: 160px;
    height: 60px;
    right: -96px;
  }
  .fixed-btn img {
    max-width: 40px;
    max-height: 40px;
  }
}
</style>
