<template>
  <v-container class="caption" fluid>
    <v-row class="text-center" align="center">
      <v-col class="title" cols="12">
        <h2 class="pm_orange--text">Trae a tu pulga</h2>
        <h4 class="white--text">y conócenos</h4>
      </v-col>
      <v-col cols="12">
        <v-btn outlined color="white" to="/Contacto">
          <p class="mb-0">
            <span class="pm_yellow--text">Haz click aquí</span>
            &nbsp; para agendar tu visita
          </p>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Caption",
  data() {
    return {};
  }
};
</script>

<style>
.caption {
  background-image: url("../assets/backgrounds/bg_caption_v2.jpg");
  background-size: cover;
  background-position: center center;
  padding: 10rem !important;
}
.caption .title {
  margin-bottom: 20px;
}
.caption h2 {
  font-size: 50px;
  line-height: 50px;
}
.caption h4 {
  font-size: 30px;
  line-height: 42px;
}
.caption p {
  font-size: 22px;
  font-weight: 800;
}
.caption .v-btn:not(.v-btn--round).v-size--default {
  height: auto;
  padding: 14px;
}
@media (max-width: 768px) {
  .caption {
    padding: 6rem !important;
  }
}
@media (max-width: 450px) {
  .caption {
    padding: 1rem !important;
  }
  .caption .title {
    margin-bottom: 0px;
  }
  .caption h2 {
    font-size: 30px;
    line-height: 36px;
  }
  .caption h4 {
    font-size: 20px;
    line-height: 32px;
  }
  .caption p {
    font-size: 12px;
  }
}
@media (max-width: 330px) {
  .caption h4 {
    font-size: 20px;
    line-height: 32px;
  }
  .caption p {
    font-size: 10px;
  }
}
</style>
