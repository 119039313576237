<template>
  <div>
    <IconAndTitle class="pt-12" :icon="icon" :title="title" />
    <v-container>
      <v-row no-gutters justify="center">
        <v-col cols="12" sm="7" lg="6">
          <p class="text-center">
            Si estás interesado en ser parte de esta gran familia sigue estos 3
            ágiles y rápidos pasos. Lo importante aquí es conocernos mejor!
          </p>
        </v-col>
      </v-row>
    </v-container>
    <Requisitos :items="items" />
    <Faq :faq="faq" />
    <Footer />
  </div>
</template>

<script>
import Requisitos from "../components/Requsitos.vue";
import Faq from "../components/Faq.vue";
export default {
  name: "Admisiones",
  components: {
    Requisitos,
    Faq
  },
  data() {
    return {
      icon: require("@/assets/icons/icon_admissions.svg"),
      title: "requisitos de admisión",
      items: [
        {
          icon: require("@/assets/icons/number1_icon.png"),
          text:
            "Visita nuestras instalaciones, comunícate al 3135996700 o al fijo (031) 643-2891 para darte una cita o si prefieres... Agendar cita",
          btnText: "Agendar Cita",
          btnLink: "/Contacto"
        },
        {
          icon: require("@/assets/icons/number2_icon.png"),
          text:
            "Escoge el mejor horario para visitarnos preferiblemente con tu pulga para conocernos mejor, ese día cómodo llenarás un formulario."
        },
        {
          icon: require("@/assets/icons/number3_icon.png"),
          text:
            "Luego de conocernos mejor y brindarles una atención personalizada. ¡Empezamos el proceso de matrículas!",
          msg: "*Respetamos el límite de cupos por salón."
        }
      ],
      faq: [
        {
          title: "Metodo de adaptación",
          description:
            "Los niños de 18 meses que están en etapa de adaptación e ingresan al jardín, tienen la opción de asistir todos los días o 3 días a la semana. Al empezar esta etapa, los niños están acompañados por sus padres hasta que se sienten en un lugar tranquilo y seguro para permanecer solos. Nuestro objetivo es fomentar el reconocimiento de su autonomía para el desarrollo de todas sus potencialidades en un ambiente que le brinde seguridad y amor."
        },
        {
          title: "¿Cómo es una clase?",
          description: "Cada grupo cuenta con su propia metodología.",
          content: [
            "Un ejemplo: ",
            "Rutina de saludo.",
            "Trabajo parte cognitiva más atención y concentración.",
            "Gimnasia / clase.",
            "Onces – recreo.",
            "Clase de arte (desarrollando la motricidad fina y exploramos nuestras emociones).",
            "Nuestros días siempre terminan con una lectura apoyándonos con títeres o imágenes del libro, o algún material que hayamos elaborado en clase."
          ]
        },
        {
          title: "Informes",
          description: "3 entregas de informes al año."
        },
        {
          title: "Salidas pedagógicas",
          description: "sí, ¡contamos con 2 salidas al año!"
        },
        {
          title: "Accidentes (en caso)",
          description:
            "contamos con un servicio de respuesta rápida que llega al jardín"
        },
        {
          title: "Los papás pueden entrar al jardín",
          description:
            "sí, jardín puertas abiertas, queremos que los papás aprovechen estando con sus hijos en esta época, ya que cuando entren al colegio no van a tener esa posibilidad."
        },
        {
          title: "Uniforme",
          description: "sudadera, pantaloneta, camiseta."
        },
        {
          title: "Control de esfínteres",
          description: "sí, les enseñamos a tener control de esfínteres."
        }
      ]
    };
  }
};
</script>

<style>
.download {
  padding-top: 6rem !important;
  margin-bottom: 36rem !important;
}
.download .btn-download span {
  font-size: 20px;
  font-weight: 800;
}
.download .btn-download-2 {
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 800;
  padding: 1rem;
}
.download .btn-download-2 i {
  color: #fff;
}
.download .btn-download-2 span {
  font-size: 14px;
  font-weight: 800;
}
@media (max-width: 768px) {
  .download .btn-download span {
    font-size: 18px;
    font-weight: 800;
  }
}
@media (max-width: 400px) {
  .download {
    padding-top: 2rem !important;
    margin-bottom: 33rem !important;
  }
}
</style>
