import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#ee44aa", // Fucsia
        secondary: "#424242", // Negro
        accent: "#82B1FF", // Azul celeste
        error: "#FF5252", // Rojo
        info: "#2196F3", // Azul
        success: "#4CAF50", // Verde
        pm_yellow: "#FDD363", // Amarillo
        pm_blue: "#5DBDB1",
        pm_orange: "#F16744",
        pm_purple: "#A940A3",
        warning: "#FDD363", // Amarillo
        Amarillo: "#FDD363", // Amarillo
        Azul: "#5DBDB1",
        Naranja: "#F16744",
        Morado: "#A949A3",
        iconColor: "#B3905b", // Marrón
        iconText: "#FDD363", // Amarillo
        navColor: "#efebda", //Blanco
        AzulDark: "#1D2244"
      }
    }
  }
});
