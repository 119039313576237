<template>
  <v-container class="icon-title">
    <v-row>
      <v-col class="text-center" cols="12">
        <img :src="icon" alt="" />
        <h2 :class="`${color}--text`">{{ title }}</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "IconAndTitle",
  props: {
    icon: {
      type: String,
      default: require("@/assets/icons/icon_home.svg")
    },
    title: {
      type: String,
      default: "Title section"
    },
    color: {
      type: String,
      default: "pm_blue"
    }
  }
};
</script>

<style>
.icon-title img {
  width: 100px;
  margin-bottom: 1rem;
}
.icon-title h2 {
  font-size: 32px;
  color: #5dbdb1;
}
</style>
