<template>
  <div class="como-lo-hacemos">
    <IconAndTitle class="pt-12" :icon="icon" :title="title" />
    <ComoComponent :texts="texts" :scrollBtn="scrollBtn" />
    <Estimulacion id="estimulacion" />
    <v-container class="mt-6">
      <v-row justify="center">
        <v-col class="text-center" cols="12" md="8">
          <p>
            Construimos vínculos con los padres y abuelos generando espacios
            ideales para interactuar con sus compañeros, es fundamental que al
            empezar esta etapa los niños estén acompañados hasta que se sientan
            tranquilos y seguros para permanecer solos, sin importar el tiempo
            que les tome, la idea es generar una transición adecuada de la casa
            al jardín.
          </p>
          <p>
            Un día a la semana realizamos rotaciones por las diferentes aulas,
            trabajando así a profundidad las diferentes áreas de desarrollo;
            área cognoscitiva, pre-matemáticas, socio-emocional, lenguaje
            lenguaje motricidad gruesa y motricidad física.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <div :id="level.id" v-for="(level, index) in levels" :key="index">
      <IconAndTitle
        class="pt-12"
        :icon="level.icon"
        :title="level.title"
        :color="level.color"
        :key="level.id"
      />
      <v-container>
        <v-row
          justify="center"
          v-for="(item, index) in level.levels"
          :key="index"
        >
          <v-col cols="12">
            <h2 class="text-center mb-6" :class="`${level.subColor}--text`">
              {{ item.title }}
            </h2>
          </v-col>
          <v-col
            class="text-center mb-4"
            cols="12"
            md="4"
            v-for="(img, index) in item.images"
            :key="index"
          >
            <img class="img-group" :src="img" alt="" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <IconAndTitle
      id="actividades"
      class="pt-12"
      :icon="actividades.icon"
      :title="actividades.title"
      :color="actividades.color"
    />
    <Actividades />
    <IconAndTitle
      id="en-casa"
      class="pt-12"
      :icon="enCasa.icon"
      :title="enCasa.title"
    />
    <v-container class="actividades">
      <v-row justify="center">
        <v-col
          v-for="(course, index) in pulgasCasa"
          class="text-center mb-10"
          cols="12"
          sm="4"
          lg="3"
          :key="index"
        >
          <h3 :class="`${course.color}--text`">{{ course.title }}</h3>
          <p>
            <small>({{ course.edad }})</small>
          </p>
          <v-btn :color="course.color" :href="course.link" target="_blank" dark>
            Descargar Actividad
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Estimulacion from "../components/Estimulacion.vue";
import ComoComponent from "../components/ComoComponent.vue";
import Actividades from "../components/Actividades.vue";
export default {
  name: "ComoLoHacemos",
  components: {
    Estimulacion,
    ComoComponent,
    Actividades
  },
  data() {
    return {
      icon: require("@/assets/icons/icon_como_hacemos.svg"),
      title: "¿Como lo hacemos?",
      actividades: {
        icon: require("@/assets/icons/icon-actividades.svg"),
        title: "Actividades lúdicas",
        color: "pm_purple"
      },
      enCasa: {
        icon: require("@/assets/icons/icon_about.svg"),
        title: "Pulgas en casa"
      },
      texts: [
        {
          title: "ADAPTACIÓN",
          data:
            "Nos reunimos con la directora para explicar método de adaptación, ya que cada niño es único y no queremos “generalizarlo”"
        },
        {
          title: "CLASES",
          data:
            "Durante la semana los niños tienen clase de: música, baile (expresión corporal), gimnasia, arte y yoga"
        }
      ],
      scrollBtn: [
        {
          name: "Estimulación adecuada",
          to: "estimulacion"
        },
        {
          name: "Pre-jardin",
          to: "pre-jardin"
        },
        {
          name: "Jardín",
          to: "jardin"
        },
        {
          name: "Actividades",
          to: "actividades"
        },
        {
          name: "Pulgas en casa",
          to: "en-casa"
        }
      ],
      levels: [
        {
          id: "pre-jardin",
          icon: require("@/assets/icons/icon-pre-jardin.svg"),
          title: "Pre-jardín",
          color: "pm_purple",
          subColor: "pm_blue",
          levels: [
            {
              title: "Pre-jardin 1",
              images: [
                require("@/assets/clases/grupo-azul-pre.svg"),
                require("@/assets/clases/grupo-verde-pre.svg")
              ]
            },
            {
              title: "Pre-jardin 2",
              images: [require("@/assets/clases/grupo-rojo-pre.svg")]
            },
            {
              title: "Pre-jardin 3",
              images: [
                require("@/assets/clases/grupo-naranja-pre.svg"),
                require("@/assets/clases/grupo-amarillo-pre.svg"),
                require("@/assets/clases/grupo-morado-pre.svg")
              ]
            }
          ]
        },
        {
          id: "jardin",
          icon: require("@/assets/icons/icon-jardin.svg"),
          title: "Jardín",
          color: "pm_blue",
          subColor: "pm_purple",
          levels: [
            {
              title: "Jardin 1 y Jardin 2",
              images: [
                require("@/assets/clases/grupo-amarillo-jar.svg"),
                require("@/assets/clases/grupo-morado-jar.svg")
              ]
            }
          ]
        }
      ],
      pulgasCasa: [
        {
          title: "Coloreador",
          edad: "todas las edades",
          color: "AzulDark",
          link: "http://64.227.10.191/pulgasAPI/files/A-Z.pdf"
        },
        {
          title: "Actividades 1",
          edad: "Niños 3-5 años",
          color: "pm_blue",
          link: "http://64.227.10.191/pulgasAPI/files/Actividades_1.pdf"
        },
        {
          title: "Actividades 2",
          edad: "Niños 3-5 años",
          color: "error",
          link: "http://64.227.10.191/pulgasAPI/files/Actividades_2.pdf"
        },
        {
          title: "Actividades 3",
          edad: "Diferente e iguales",
          color: "pm_orange",
          link: "http://64.227.10.191/pulgasAPI/files/Actividades_3.pdf"
        },
        {
          title: "Actividades 4",
          edad: "Profesiones",
          color: "success",
          link: "http://64.227.10.191/pulgasAPI/files/Actividades_4.pdf"
        }
      ]
    };
  }
};
</script>

<style>
.img-group {
  min-width: 160px;
  max-width: 200px;
  max-height: 160px;
}
.actividades {
  padding-bottom: 12rem !important;
}
@media (max-width: 1024px) {
  .actividades {
    padding-bottom: 24rem !important;
  }
}
@media (max-width: 768px) {
  .actividades {
    padding-bottom: 32rem !important;
  }
}
</style>
