<template>
  <v-container fluid class="estimulación">
    <v-row align="center">
      <v-col
        class="d-flex justify-center align-center"
        cols="12"
        offset-md="1"
        md="4"
      >
        <img class="la_mona" src="@/assets/gallery/estimulacion.png" />
      </v-col>
      <v-col class="description text-center" cols="12" md="6">
        <h3 class="white--text mb-5">ESTIMULACIÓN ADECUADA</h3>
        <p class="white--text">
          Al estimular a tu bebé adecuadamente le ayudaras a adquirir y
          habilidades mientras explora y experimenta de manera sana y natural.
          <span>
            Nuestras clases se caracterizan por ser personalizada (7 niños por
            clase),
          </span>
          enseñamos por medio del juego y el respeto del ser, haciendo énfasis
          en las personalidades de cada uno.
        </p>
        <p class="white--text">
          <span>
            Trabajamos con bebés de 6 meses a 2 años,
          </span>
          y cada grupo está dividido con maximo 3 meses de diferencia entre
          compañeros.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Estimulacion"
};
</script>

<style>
.estimulación {
  background-image: url("../assets/backgrounds/bg_estimulacion.jpg");
  background-size: cover;
  background-position: center center;
}
.estimulación .la_mona {
  width: 80%;
}
.estimulación .description {
  padding: 2rem 8rem;
}
.estimulación .description h3 {
  font-size: 24px;
}
.estimulación .description h4 {
  font-size: 20px;
}
.estimulación .description p {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}
.estimulación .description p span {
  font-weight: bold;
}
.estimulación p {
  font-size: 18px;
}
@media (max-width: 1200px) {
  .estimulación .description {
    padding: 2rem 4rem;
  }
  .estimulación .la_mona {
    width: 90%;
  }
}
@media (max-width: 1200px) {
  .estimulación .la_mona {
    width: 400px;
  }
}
@media (max-width: 450px) {
  .estimulación .description {
    padding: 2rem;
  }
  .estimulación .description h3 {
    line-height: 28px;
  }
  .estimulación .la_mona {
    width: 300px;
  }
}
</style>
