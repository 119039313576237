<template>
  <v-container>
    <v-row
      v-for="(item, index) in items"
      :key="index"
      class="text-center"
      justify="center"
    >
      <v-col cols="12" sm="6" lg="4">
        <img :src="item.icon" alt="" />
        <p class="mb-0">{{ item.text }}</p>
        <p class="pm_purple--text" style="font-weight: bold;" v-if="item.msg">
          {{ item.msg }}
        </p>
        <v-btn
          class="pm_orange white--text px-12 mt-4"
          v-if="item.btnLink"
          :to="item.btnLink"
          depressed
          large
        >
          {{ item.btnText }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Requistos",
  props: {
    items: Array
  }
};
</script>
