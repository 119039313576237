<template>
  <div class="nosotros">
    <Banner />
    <IconAndTitle :icon="icon" :title="title" />
    <Padres id="#padres" />
    <Directora id="#directora" />
    <h2 class="team text-center pm_blue--text">Nuestro equipo</h2>
    <OurTeam id="equipo" class="team-nosotros" />
    <Footer />
  </div>
</template>

<script>
import Banner from "../components/Banner";
import Padres from "../components/Padres";
import Directora from "../components/Directora";
import OurTeam from "../components/OurTeam";
export default {
  name: "nosotros",
  components: {
    Banner,
    Padres,
    Directora,
    OurTeam
  },
  data() {
    return {
      icon: require("@/assets/icons/icon_about.svg"),
      title: "Papás y mamás"
    };
  }
};
</script>

<style>
.nosotros .team {
  font-size: 34px;
}
.construye .description {
  padding: 8rem;
  order: 1;
}
.construye .image {
  padding: 8rem;
  order: 2;
}
.construye h3 {
  font-size: 26px;
}
.team-nosotros {
  margin-bottom: 32rem;
}
@media (max-width: 1024px) {
  .what-is {
    padding: 1rem !important;
  }
  .what-is .alguna-vez {
    font-size: 26px;
    line-height: 34px;
  }
  .what-is .specialize {
    font-size: 18px;
  }
  .mama-directora .description {
    padding: 2rem;
  }
  .construye .description {
    padding: 2rem;
  }
  .construye h3 {
    font-size: 24px;
  }
  .team-nosotros {
    margin-bottom: 36rem;
  }
}
@media (max-width: 768px) {
  .mama-directora .alguna-ves {
    font-size: 20px;
  }
  .mama-directora p {
    margin-bottom: 0rem !important;
  }
  .construye .description {
    order: 2;
  }
  .construye .image {
    order: 1;
  }
  .what-is {
    padding: 1rem !important;
  }
  .mama-directora .description {
    padding: 1rem;
  }
  .construye .description {
    padding: 1rem;
  }
  .team-nosotros {
    margin-bottom: 32rem;
  }
}
@media (max-width: 450px) {
  .what-is {
    padding: 1rem !important;
    margin-bottom: 0rem;
  }
  .what-is .alguna-vez {
    font-size: 20px !important;
    line-height: 28px !important;
  }
  .what-is .alguna-vez {
    font-size: 26px;
    line-height: 34px;
  }
  .what-is .specialize {
    font-size: 18px;
  }
  .mama-directora {
    margin-bottom: 0;
  }
  .mama-directora .description {
    padding: 1rem;
  }
  .construye .description {
    padding: 1rem;
    margin-top: 1rem;
  }
  .construye h3 {
    font-size: 22px;
  }
  .team-nosotros {
    margin-bottom: 36rem;
  }
}
</style>
