<template>
  <div
    class="underConstruction"
    v-bind:style="{ 'background-image': 'url(' + bg_image + ')' }"
  ></div>
</template>

<script>
export default {
  name: "UnderConstruction",
  data() {
    return {
      bg_image: require("@/assets/under_construction.png")
    };
  }
};
</script>

<style>
.underConstruction {
  background-position: center;
  background-size: cover;
  height: 100vh;
}
</style>
