<template>
  <v-container class="our-team">
    <v-row>
      <v-col
        v-for="(item, index) in team"
        :key="index"
        class="pa-8"
        cols="12"
        sm="6"
        md="4"
      >
        <div class="card">
          <div
            class="card-img"
            :style="{ 'background-image': 'url(' + item.img + ')' }"
          ></div>
          <div class="card-content">
            <h4>{{ item.name }}</h4>
            <h5>{{ item.position }}</h5>
            <h6>{{ item.career }}</h6>
            <p class="card-text">
              {{ item.description }}
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OurTeam",
  data() {
    return {
      team: [
        {
          name: "Laura Romero",
          img: require("@/assets/team/Laura_Romero.webp"),
          position: "Profesora",
          career: "Pedagoga primera infancia",
          description:
            "Comprometida con la mediación entre experiencias significativas y el aprendizaje a través del juego, el pensamiento lógico y la creatividad. La escucha, el cuidado y el amor son elementos básicos para comprender a cada niño y niña desde sus potencialidades y características particulares en función de su desarrollo y respeto hacia sus propios ritmos.  La labor diaria me permite adecuar espacios, validar procesos y brindar herramientas para el encuentro con el aprendizaje desde la emoción, el movimiento, la exploración y los intereses de los mismos niños."
        },
        {
          name: "Laura Rojas Molina",
          img: require("@/assets/team/laura_rojas1.jpg"),
          position: "Profesora",
          career: "Terapeuta ocupacional",
          description:
            "Mi objetivo es apoyar y favorecer el desempeño de los niños en las tareas y actividades escolares, la adaptación a su entorno y brindarles herramientas para lograr una independencia en las actividades de la vida diaria; así mismo realizar un acompañamiento a los padres en el proceso educativo."
        },
        {
          name: "Claudia Angulo (Pelusa)",
          img: require("@/assets/team/claudia_angulo1.jpg"),
          position: "Profesora",
          career: "Pedagoga preescolar",
          description:
            "Con más de 20 años de experiencia en planeación, organización y ejecución de programas educativos dirigidos a niños entre 18 meses y 4 años; estoy felizmente casada con 2 hijos de 30 y 25 años, me encanta trabajar en las Pulgas de La Mona por su buen ambiente laboral y camaradería. Cada día se vive la mejor energía, el amor y entrega por el bienestar de los niños."
        },
        {
          name: "Lucila Moreno",
          img: require("@/assets/team/Lucila_Moreno.webp"),
          position: "Profesora",
          career: "Licenciada en preescolar y neuropsicopedagoga",
          description:
            "Tengo 25 años trabajando con niños en edad preescolar, 10 con educación precoz y 10 con enseñanza para la comprensión, soy directora del grupo Morado y la encargada de  preparar a los chiquitos para su ingreso a colegio."
        },
        {
          name: "Viví Acosta",
          img: require("@/assets/team/vivi_acosta1.jpg"),
          position: "Profesora",
          career: "Fonoaudióloga",
          description:
            "Con 24 años de experiencia. Tengo un hijo de 18 años, Juan Nicolás Suárez. Pertenecer al equipo de Las Pulgas de la Mona me hace feliz; es un privilegio poder compartir con los niños en la etapa más importante de su vida y disfruto cada momento que paso con ellos. Sus sonrisas, palabras, abrazos, la ternura y la espontaneidad hacen que mi trabajo sea el mejor de todos. Estoy aquí por vocación más no por equivocación."
        },
        {
          name: "Johana Morales",
          img: require("@/assets/team/johana_morales1.jpg"),
          position: "Profesora",
          career: "Psicóloga",
          description:
            "Mamá de dos princesas de 9 años y una bebe de 10 meses. Cuento con 13 años de experiencia con niños de la primera infancia. Lo que más me gusta de estar en Las Pulgas de La Mona es la capacidad de aprender con cada niño y niña, compartir sus primeros pasos, sus primeras palabras, el desarrollo de sus habilidades, y capacidades que me hacen sentir orgullosa de la huella que dejamos en ellos en un ambiente de armonía, cariño y mucho respeto en familia."
        },
        {
          name: "Maria del Pilar Gonzalez",
          img: require("@/assets/team/Maria_del_Pilar_Gonzalez.webp"),
          position: "Profesora grupo Naranja",
          career: "Fonoaudiologa Profesional egresado de la EScuela Colombiana de Rehabilitación Tríale",
          description:
            "Durante 21 años en la Clínica del country como supervisora de fonoaudiología en clinica y consulta externa externa manejando  todo tipo de población desde recién nacidos hasta adulto mayor, en patologías como dificultades para la lactancia materna, problemas de alimentación, Problemas de Aprendizaje, dificultades de Habla y lenguaje y Disfagia entre otras."
        },
        {
          name: "Patricia Salazar",
          img: require("@/assets/team/Patricia_Salazar.webp"),
          position: "Profesora titular del grupo Rojo",
          career: "Docente en educación preescolar",
          description:
            "Llevo muchos años trabajando con niños y cada día estoy más segura de lo que estudié. Me siento identificada con estos pequeñitos los cuáles diariamente me dejan muchas enseñanzas y me hacen ser una docente muy feliz.  Siempre entrego lo mejor de mi en mi trabajo y  estoy muy dispuesta a aprender  cosas nuevas las cuales puedo transmitir a los niños con mucho amor y dedicación."
        },
        {
          name: "Paula Andrea Ocampo",
          img: require("@/assets/team/Paula_Andrea_Ocampo.webp"),
          position: "Docente",
          career: "Licenciada en Biología con gran  experiencia en atencion a la primera infancia",
          description:
            "Soy Paula, llevo más de 6 años como docente preescolar, disfruto el aprendizaje  que cada día  obtengo al  estar con los niños, me llenan de motivación para hacer de mi trabajo una vocación."
        },
        {
          name: "Claudia Guasca",
          img: require("@/assets/team/Claudia_Guasca.webp"),
          position: "Auxiliar",
          career: "Técnica profesional en atención primera infancia",
          description:
            "Cuento con 12 años de experiencia, soy casada con dos hijos de 22 y 12 años y un nieto de tres años. Me encanta trabajar en Las Pulgas de La Mona pues es un lugar donde los niños aprenden y se divierten al mismo tiempo se divierten. ¡Todos aprendemos y somos una gran familia!"
        },
        {
          name: "Johanna Jiménez",
          img: require("@/assets/team/Johana_Jimenez.webp"),
          position: "Auxiliar",
          career: "Técnica laboral en atención primera infancia",
          description:
            "Cuento con una experiencia de 7 años trabajando con niños. Estoy  casada, tengo dos hijas de 16 y 6 años. Me gusta trabajar en Las Pulgas de La Mona porque somos una gran familia."
        },
        {
          name: "Ingrith Rodríguez",
          img: require("@/assets/team/ingrid_rodriguez1.jpg"),
          position: "Auxiliar",
          career: "Auxiliar técnica en atención primera infancia",
          description:
            "Con 7 años de experiencia. Soy mamá de 2 hijos de 17 y 15 años de edad. Lo que más me fascina de Las Pulgas de La Mona es que somos una gran familia dedicada y comprometida, vivimos nuestro día a día aprendiendo de los niños que nos contagian su alegría y su manera de querer explorar el mundo."
        },
        {
          name: "Angie Arévalo",
          img: require("@/assets/team/angie_arevalo1.jpg"),
          position: "Auxiliar",
          career: "Licenciatura en pedagogía infantil",
          description:
            "Actualmente curso séptimo semestre de psicología. Estoy casada, tengo dos hijos de 9 y 4 años. Cuento con una experiencia de 4 años trabajando con niños, me gusta ser parte de Las Pulgas de La Mona porque juntas somos un gran equipo de trabajo."
        },
        {
          name: "Janeth Bermúdez",
          img: require("@/assets/team/janeth_bermudez1.jpg"),
          position: "Auxiliar",
          career: "Técnico en Desarrollo infantil",
          description:
            "Tengo 10 años de experiencia en el campo. Soy madre de una niña de 9 años. Lo que más me gusta de trabajar en Las Pulgas de La Mona es el aprendizaje mediante el juego, donde exploramos y compartimos alegrías de amor en familia."
        },
        {
          name: "Rosmary Benítez",
          img: require("@/assets/team/rosmary_benitez1.jpg"),
          position: "Servicios generales",
          description:
            "Trabajó en las Pulgas de la Mona hace 14 años en el cargo de oficios varios. Soy mamá de tres hijos de 37, 19 y 16 años. Para mi Las Pulgas de La Mona es un jardín que cuenta con excelentes profesoras que además de enseñar son muy amorosas, queridas y comprensivas con los niños; somos una gran familia y nos divertimos todos los días."
        },
        {
          name: "Yudy Jaramillo",
          img: require("@/assets/team/Yudi_Jaramillo.webp"),
          position: "Área Administrativa",
          career: "Profesional primera infancia",
          description:
            "Soy mamá de Julián de 15 años y María Paula de 9 años. Trabajo en las Pulgas de la Mona hace 13 años, me desempeño en el área administrativa. Lo que más me gusta de mi trabajo es brindarle apoyo a los papás buscando el beneficio de los niños. También asisto La Mona en diferentes funciones logrando mantener la unión, cariño y respeto para esta gran la familia."
        },
        {
          name: "Elizabeth Restrepo",
          img: require("@/assets/team/Elizabet_Restrepo.webp"),
          position: "Profesora de Música",
          career: "Licenciada en pedagogía musical",
          description:
            "Licenciada en pedagogía musical de la universidad Pedagógica Nacional. Soy cantante de formación, me ha centrado en la canción infantil como principal recurso para crear, improvisar y conectarse con el mundo de los niños. He participado en numerosos encuentros y talleres de educación musical a nivel nacional e internacional dirigidos a todo mi saber creativo hacia la primera infancia."
        },
        {
          name: "Cristina Espinel",
          img: require("@/assets/team/cristina_espinel1.jpg"),
          position: "Profesora Estimulación Adecuada",
          career: "Terapeuta Ocupacional",
          description:
            "Con experiencia de 25 años en estimulación temprana y en el desarrollo de los niños. Soy mamá de Samuel quien tiene 14 años y llevo casada 16. Lo que más me gusta de mi trabajo es ver felices a los niños,  disfrutar y aprender cada día de sus vivencias. Me siento orgullosa cuando logró avances en cada uno de ellos."
        }
      ]
    };
  }
};
</script>

<style>
.card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 5px 5px -3px #000000aa;
}
.card .card-img {
  height: 420px;
  background-position: center;
  background-size: cover;
  transition: 0.5s;
}
.card .card-img img {
  width: 100%;
  height: auto;
}
.card .card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  background: #8a67d900;
  color: #fff;
  transition: all 0.8s;
}
.card:hover .card-content {
  bottom: 0;
  background: #8a67d9;
}
.card .card-content h4,
.card .card-content h5,
.card .card-content h6 {
  text-transform: none;
  font-family: "Open Sans", sans-serif !important;
}
.card .card-content h4 {
  font-size: 18px;
}
.card .card-content h5 {
  font-weight: 400;
  font-size: 16px;
}
.card .card-content h6 {
  font-size: 14px;
  font-weight: 400;
}
.card .card-content .card-text {
  margin-top: 1.5rem;
  font-size: 14px;
}
@media (max-width: 450px) {
  .card .card-img {
    height: 440px;
  }
  .card .card-content {
    padding: 15px;
  }
  .card .card-content h5 {
    font-size: 14px;
  }
  .card .card-content h6 {
    font-size: 13px;
  }
  .card .card-content .card-text {
    font-size: 13px;
  }
}
.our-team.team-nosotros {
  width: 95%;
}
@media (min-width: 1904px) {
  div#equipo {
    width: 70%;
  }
}
</style>
