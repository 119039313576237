import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Nosotros from "../views/Nosotros.vue";
import Como from "../views/ComoLoHacemos.vue";
import Admisiones from "../views/Admisiones.vue";
import Contacto from "../views/Contacto.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { title: "Inicio" }
  },
  {
    path: "/Nosotros",
    name: "Nosotros",
    component: Nosotros,
    meta: { title: "Nosotros" }
  },
  {
    path: "/Como-lo-hacemos",
    name: "Como",
    component: Como,
    meta: { title: "Como lo hacemos" }
  },
  {
    path: "/Admisiones",
    name: "Admisiones",
    component: Admisiones,
    meta: { title: "Admisiones" }
  },
  {
    path: "/Contacto",
    name: "Contacto",
    component: Contacto,
    meta: { title: "Contacto" }
  }
];

const scrollBehavior = function(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  } else {
    var position = {};
    if (to.hash == "#padres") {
      return (position.offset = { y: 0 });
    } else if (to.hash == "#directora") {
      return (position.offset = { y: 1100 });
    } else if (to.hash == "#equipo") {
      return (position.offset = { y: 1900 });
    }
  }
  return { x: 0, y: 0 };
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
